<template>
	<main-tabs :links="links">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/fb-ads/carousel'
					},
					label: '轮播广告'
				}, {
					to: {
						path: '/fb-ads/single'
					},
					label: '单图广告'
				}, {
					to: {
						path: '/fb-ads/video'
					},
					label: '视频广告'
				}]
			}
		},
		watch: {
			'$route'(_to, _from) {
				if (_to.path.startsWith('/fb-ads/carousel') && _from.path.startsWith('/fb-ads/carousel')) {
					this.links[0].to.path = _to.path
				}
			}
		}
	}
</script>
